import { VAS } from '@packages/codegen/dhl_parcel_de_shipping_v2_client';
import { DHL_PRODUCT } from '@packages/static/dhl';
import { RuleProperties } from 'json-rules-engine';

import { ShipmentRuleEvent } from '@/lib/rules-engine/types';

import { WarehouseProduct } from '../warehouse-types';

import engelsinnProducts from './merger/products-engelsinn.json';
import novahomeProducts from './merger/products-novahome.json';
import productsSandbox from './merger/products-sandbox.json';

export const tenantConfig: {
	[key: string]: {
		name: string;
		tenantId: string;
		address: {
			name1: string;
			street: string;
			house: string;
			zip: string;
			city: string;
			country: string;
		};
		products: WarehouseProduct[];
		shippingRules: RuleProperties[];
	};
} = {
	't#01HV1E9Q91P7CBDM72PC13AKPS': {
		name: 'kania',
		tenantId: 't#01HV1E9Q91P7CBDM72PC13AKPS',
		address: {
			name1: 'Wilm Beer GbR',
			street: 'Sachsenstraße',
			house: '6',
			zip: '92318',
			city: 'Neumarkt',
			country: 'DEU',
		},
		products: [] as WarehouseProduct[],
		shippingRules: [
			{
				conditions: {
					any: [],
				},
				event: {
					type: 'DHL Warenpost 0-1kg',
					params: {
						shipmentProduct: `DHL:${DHL_PRODUCT.V62WP}`,
					},
				} satisfies ShipmentRuleEvent,
			},
			{
				conditions: {
					any: [
						{
							fact: 'skus',
							operator: 'matches',
							value: '1000-\\d{2}-?',
						},
					],
				},
				event: {
					type: 'DHL_VAS',
					params: {
						shipmentProduct: `DHL:${DHL_PRODUCT.V01PAK}`,
						services: {
							signedForByRecipient: true,
						} satisfies VAS,
					},
				},
			},
		],
	},
	't#01HDX9DT849T3MT1VYDYZC4J0X': {
		name: 'sandbox',
		tenantId: 't#01HDX9DT849T3MT1VYDYZC4J0X',
		address: {
			name1: 'Bastian Kistner IT-Services',
			street: 'Bernfurther Mühle',
			house: '4c',
			zip: '92318',
			city: 'Neumarkt',
			country: 'DEU',
		},
		products: productsSandbox as WarehouseProduct[],
		shippingRules: [],
	},
	't#01HDHAW7MB7A50B63M2KJP3J9V': {
		name: 'engelsinn',
		tenantId: 't#01HDHAW7MB7A50B63M2KJP3J9V',
		address: {
			name1: 'Wilm Beer GbR',
			street: 'Sachsenstraße',
			house: '6',
			zip: '92318',
			city: 'Neumarkt',
			country: 'DEU',
		},
		products: engelsinnProducts as WarehouseProduct[],
		//  (await import('./engelsinn-products-12-11-23.json', {
		// assert: { type: 'json' },
		// })) as GetProductVariantsQuery['productVariants']['nodes'],
		shippingRules: [
			{
				conditions: {
					any: [],
				},
				event: {
					type: 'DHL Warenpost 0-1kg',
					params: {
						shipmentProduct: `DHL:${DHL_PRODUCT.V62WP}`,
					},
				} satisfies ShipmentRuleEvent,
			},
			{
				conditions: {
					any: [
						{
							fact: 'skus',
							operator: 'matchesOneOf',
							value: [
								'10',
								'11',
								'12',
								'13',
								'14',
								'15',
								'16',
								'17',
								'18',
								'19',
								'20',
								'21',
								'22',
								'23',
								'24',
								'7050',
								'8100',
								'8105',
								'8110',
								'8115',
								'8120',
								'8125',
								'8130',
								'8131',
								'8132',
								'8150',
								'8170',
								'8171',
								'8172',
								'8173',
								'8174',
								'8200',
								'8201',
								'8202',
								'8203',
								'8204',
								'8205',
								'8206',
								'8207',
								'8208',
								'8209',
								'8210',
								'8211',
								'8212',
								'8213',
								'8214',
								'8250',
								'8270',
								'8271',
								'8310',
								'8311',
								'8315',
								'8316',
								'8400',
								'8401',
								'8403',
								'8402',
							],
						},
						{
							all: [
								{
									fact: 'skus',
									operator: 'matchesOneOf',
									value: ['8101', '8106', '8111', '8116', '8121', '8126', '8133', '8134', '8135', '8151', '8171'],
								},
								{
									fact: 'skus',
									operator: 'sizeGreaterThan',
									value: 1,
								},
							],
						},
					],
				},
				event: {
					type: 'DHL PAKET',
					params: {
						// GoGreen will be derived from the billingNumber
						shipmentProduct: `DHL:${DHL_PRODUCT.V01PAK}`,
					},
				} satisfies ShipmentRuleEvent,
			},
			{
				conditions: {
					all: [
						{
							fact: 'skus',
							operator: 'containsNumericBetweenInclusive',
							value: [1, 200],
						},
						{
							not: {
								fact: 'skus',
								operator: 'matchesOneOf',
								value: ['27', '50', '51', '52', '53'],
							},
						},
						// redeploy
						// {
						// 	fact: 'skus',
						// 	operator: 'matchesOneOf',
						// 	value: ['Sped-Wohn-3-98735'],
						// },
					],
				},
				event: {
					type: 'DHL_VAS',
					params: {
						shipmentProduct: `DHL:${DHL_PRODUCT.V01PAK}`,
						services: {
							signedForByRecipient: true,
						} satisfies VAS,
					},
				},
			},
			{
				conditions: {
					all: [
						{
							fact: 'skus',
							operator: 'matchesOneOf',
							value: ['8101', '8106', '8111', '8116', '8121', '8126', '8133', '8134', '8135', '8151', '8171'],
						},
						{
							fact: 'skus',
							operator: 'matches',
							value: '__OTTO_DISCOUNT__',
						},
						{
							fact: 'skus',
							operator: 'sizeGreaterThan',
							value: 1,
						},
					],
				},
				event: {
					type: 'DHL Warenpost 0-1kg',
					params: {
						// GoGreen will be derived from the billingNumber
						shipmentProduct: `DHL:${DHL_PRODUCT.V62WP}`,
					},
				} satisfies ShipmentRuleEvent,
			},
		],
	},
	't#01HRM3ZPGZTN8W0VMZ330X3EJC': {
		name: 'NovaHome',
		tenantId: 't#01HRM3ZPGZTN8W0VMZ330X3EJC',
		address: {
			name1: 'Wilm Beer GbR',
			street: 'Sachsenstraße',
			house: '6',
			zip: '92318',
			city: 'Neumarkt',
			country: 'DEU',
		},
		products: novahomeProducts as WarehouseProduct[],
		shippingRules: [
			{
				conditions: {
					any: [],
				},
				event: {
					type: 'DHL Warenpost 0-1kg',
					params: {
						shipmentProduct: `DHL:${DHL_PRODUCT.V62WP}`,
					},
				} satisfies ShipmentRuleEvent,
			},
			{
				conditions: {
					any: [
						{
							fact: 'totalHeightCm',
							operator: 'greaterThanInclusive',
							value: 5.0,
						},
						{
							fact: 'totalWeightGrams',
							operator: 'greaterThanInclusive',
							value: 1001,
						},
						// {
						// 	fact: 'skus',
						// 	operator: 'someEndsWith',
						// 	value: '-1',
						// },
						// {
						// 	fact: 'skus',
						// 	operator: 'someEndsWith',
						// 	value: '-2',
						// },
						// {
						// 	fact: 'skus',
						// 	operator: 'someEndsWith',
						// 	value: '-3',
						// },
						// {
						// 	fact: 'skus',
						// 	operator: 'someEndsWith',
						// 	value: '-4',
						// },
						// {
						// 	fact: 'skus',
						// 	operator: 'matchesOneOf',
						// 	value: ['8830', '8831', '8832', '8833', '8834', '8835', '8836', '8837'],
						// },
					],
				},
				event: {
					type: 'DHL PAKET',
					params: {
						// GoGreen will be derived from the billingNumber
						shipmentProduct: `DHL:${DHL_PRODUCT.V01PAK}`,
					},
				} satisfies ShipmentRuleEvent,
			},
		],
	},
};
